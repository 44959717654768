import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import StyledPost from 'components/modules/styledPost';

import { usePosts } from 'hooks/usePosts';

const StyledWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, minmax(min-content, 1fr));
  grid-template-rows: minmax(min-content, 40rem);
  grid-gap: 5rem;
  padding-bottom: 10rem;

  & > * {
    margin-top: 0;
  }

  h5 {
    text-align: center;
    margin-bottom: 3rem;
  }
  ${({ theme: { mq } }) => mq.mediumDesktop} {
    padding: 0 5rem 10rem;
  }
  ${({ theme: { mq } }) => mq.bigTablet} {
    grid-template-columns: 60rem;
    justify-content: center;
    justify-items: center;
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    padding: 0 3.3rem 10rem;
    grid-template-columns: 1fr;
  }
`;

const RecentPostBox = () => {
  const recentPosts = usePosts();

  const postsWrapper = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const recentPostBoxes = gsap.utils.toArray(
      postsWrapper.current.querySelectorAll('.recent-post'),
    );
    recentPostBoxes.forEach(eachBox => {
      gsap.from(eachBox, {
        y: '+=100',
        autoAlpha: 0,
        duration: 0.75,
        scrollTrigger: {
          trigger: eachBox,
        },
      });
    });
  });

  return (
    <StyledWrapper ref={postsWrapper}>
      {recentPosts.map(post => (
        <StyledPost key={post.node.frontmatter.title} post={post} />
      ))}
    </StyledWrapper>
  );
};

export default RecentPostBox;
