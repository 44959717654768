import styled from 'styled-components';

const WideSection = styled.section`
  grid-column: 1 / -1 !important;
  background: ${({ bgc }) => bgc};
  color: black;
  padding: ${({ position }) =>
    position === 'center' ? '10rem calc((100% - 128rem) / 2)' : '10rem 0'};
  h2 {
    color: black;
  }
`;

export default WideSection;
