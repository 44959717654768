import { graphql, useStaticQuery } from 'gatsby';

export const usePosts = () => {
  const {
    data: { edges },
  } = useStaticQuery(graphql`
    query {
      data: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              date
              description
              authors {
                author
              }
              tags
              image {
                childImageSharp {
                  fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            excerpt
          }
        }
      }
    }
  `);

  return edges;
};
